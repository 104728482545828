var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fragment',[_c('v-card-text',{staticClass:"pa-0"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.getListDevices,"data-test":"dataTable-field","items-per-page":10,"footer-props":{'items-per-page-options': [10, 25, 50, 100]},"server-items-length":_vm.getNumberDevices,"options":_vm.pagination},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.online",fn:function(ref){
var item = ref.item;
return [(item.online)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v(" check_circle ")]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v(" check_circle ")])]}}],null,true)},[_c('span',[_vm._v("last seen "+_vm._s(_vm._f("lastSeen")(item.last_seen)))])])]}},{key:"item.hostname",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'detailsDevice', params: { id: item.uid } }}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.info.pretty_name",fn:function(ref){
var item = ref.item;
return [_c('DeviceIcon',{attrs:{"icon-name":item.info.id}}),_vm._v(" "+_vm._s(item.info.pretty_name)+" ")]}},{key:"item.namespace",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"list-itens"},[_vm._v(" "+_vm._s(_vm.address(item))+" "),_c('v-icon',{attrs:{"small":"","right":""},on:{"click":function($event){_vm.copySSHID(_vm.address(item))}}},[_vm._v(" mdi-content-copy ")])],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"icons",on:{"click":function($event){return _vm.detailsDevice(item)}}},on),[_vm._v(" info ")])]}}],null,true)},[_c('span',[_vm._v("Details")])]),(item.online)?_c('TerminalDialog',{attrs:{"uid":item.uid}}):_vm._e(),_c('DeviceDelete',{attrs:{"data-test":"delete-field","uid":item.uid},on:{"update":_vm.refresh}})]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }